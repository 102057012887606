body {
	background-color: #2A2E32;
	font-family: "Roboto", sans-serif;
}

.Header {
	width: 95vw;
	height: 40px;
/*	background-color: red;
*/	margin: 20px 40px 20px 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.Hero {
	margin-top: 30vh;
	display: flex;
	justify-content: center;
}

.HeroMessage > * {
	display: flex;
	justify-content: center;
}

.HeroMessage a {
	text-decoration: none;
}

h2 {
	font-family: 'PT Serif', serif;
	font-size: 48px;
	line-height: 64px;
}

.Hero p {
	font-family: "Roboto", sans-serif;
	font-weight: 300;
}

.SignUpButton {
	border: 2px solid #fff;
	padding: 15px;
	margin-top: 40px;
	cursor: pointer;
	text-transform: uppercase;
	color: #fff;
}

.SignUpButton:hover {
	background-color: #fff;
	color: #000;
}

.SignUpButton:active {
	background-color: #f05c8a;
	color: #fff;
	border-color: #f05c8a;
}

.HeroMessage img {
	margin-top: 50px;
	width: 100vw;
}

.UserLogin {
	font-family: "Roboto", sans-serif;
	font-weight: 300;
}

.UserLogin a {
	font-family: "Roboto Mono", monospace;
	font-size: 12px;
	margin-right: 10px;
	color: #fff;
	text-decoration: none;
	border-bottom: 1px solid #292d31;
	margin-top: 1px;
}

.UserLogin a:hover {
	border-bottom: 1px solid #fff;
}


.LoginButton {
	border: 1px solid #8d8f8e;
	padding: 10px 15px 10px 15px;
	display: flex;
	justify-content: center;
	font-size: 12px;
	cursor: pointer;
}

.LoginButton:hover {
	background-color: rgba(255,255,255,0.05)
}

.LoginBar {
	display: flex;
	align-items: center;
}

strong {
	display: inline-block;
	font-weight: 500;
}