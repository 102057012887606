.DashboardContainer {
  display: flex;
  font-family: "Roboto", sans-serif;
}

.Dashboard {
  display: grid;
  grid-gap: 10px;
  column-gap: 15px;
  grid-row-gap: 15px;
  grid-template-columns: repeat(16, 50px [col-start]);
  grid-template-rows: repeat(14, 50px [col-start]);
  margin: 50px;
  overflow: hidden;
  min-height: 100vh;
}

.DashboardWidget {
	background-color: #292d31;
}


.DashboardWidget h3 {
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	font-weight: 500;
	color: #fff;
}

.DashboardWidget h4 {
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	font-weight: 300;
	color: #686b6c;
}

.ActivityGraph {
	grid-area: 1 / 1 / span 6 / span 8;
	padding: 10px;
}

.ActivityGraph .GraphContainer {
	display: flex;
	justify-content: space-between;
	width: 80%;
	margin: 60px 50px 20px 50px;
}

.ActivityGraph .GraphHeader {
	display: flex;
	justify-content: space-between;
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	font-weight: 300;
	color: #686b6c;
	margin: 20px 10px 20px 10px;
}

.ActivityGraph .GraphLabels {
	display: flex;
}

.ActivityGraph .GraphLabels div {
	margin-right: 10px;
}

.ActivityGraph .GraphLabels .circle {
	width: 10px;
	height: 10px;
	display: inline-block;
}

.ActivityGraph .BarBase {
	width: 12px;
	background-color: #febb2a;
	height: 150px;
	border-radius: 1px;
}

.ActivityGraph .BarActive {
	width: 12px;
	background-color: #46494d;
	height: 50px;
}

.ActivityGraph .Month {
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 300;
	color: #9e9f9e;
	margin-top: 5px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.ActivityGraph .GraphFooter {
	border-top: 1px solid rgba(255,255,255,0.1);
}

.ActivityGraph .UpdateStats {
	font-size: 10px;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	display: flex;
	justify-content: center;
	margin-top: 30px;
	color: #b4b5b4;
	letter-spacing: 0.3px;
}

.ProjectBreakdown {
	grid-area: 1 / 9 / span 4 / span 4;
	display: flex;
	align-items: center;
	justify-content: center;
}

.DailyCommits {
	grid-area: 5 / 9 / span 2 / span 4;
	padding: 15px 10px 10px 15px;
}

.DailyCommits > * {
	padding-bottom: 15px;
}

.TestCoverage {
	grid-area: 1 / 13 / span 2 / span 4;
	padding: 15px 10px 10px 15px;
}

.TestCoverage > * {
	padding-bottom: 15px;
}


.NewReleases {
	grid-area: 3 / 13 / span 2 / span 4;
	padding: 15px 10px 10px 15px;
}

.NewReleases > * {
	padding-bottom: 15px;
}


.ActiveDevelopers {
	grid-area: 5 / 13 / span 2 / span 4;
	padding: 15px 10px 10px 15px;
}

.ActiveDevelopers > * {
	padding-bottom: 15px;
}


.RecentProjects {
	grid-area: 7 / 1 / span 5 / span 5;
	padding: 25px 10px 10px 20px;
}

.LatestCommits {
	grid-area: 7 / 6 / span 5 / span 11;
	padding: 25px 10px 10px 20px;
	overflow: hidden;
}

.LatestCommits h3 {
	font-size: 12px;
	font-weight: 300;
}

.CommitEntry {
	border-bottom: 1px solid #3a3e45;
	padding-bottom: 10px;
	margin-bottom: 15px;
	width: 95%;
}

.CommitEntry:first-of-type {
	margin-top: 30px;
}

.CommitMetadata {
	display: flex;
	margin-bottom: 10px;
}

.CommitMetadata > * {
	margin-right: 10px;
}

.CommitMessage {
	margin-left: 20px;
	max-height: 48px;
	overflow: hidden;
}

.ReviewRequests {
	grid-area: 12 / 1 / span 5 / span 8;
	padding: 20px;
}

.TrendingGithub {
	grid-area: 12 / 9 / span 5 / span 8;
	padding: 20px;
}

.GithubTrendingGrid {
	margin-top: 20px;
	display: grid;
	column-gap: 10%;
  	grid-row-gap: 15px;
  	grid-template-columns: repeat(2, 42% [col-start]);
}

.TrendingEntryRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5px;
}

.TrendingEntryRow h4 {
	font-size: 10px;
	color: #707273;
}

.TrendingEntryRow h6 {
	font-size: 14px;
}

.TrendingEntryRow .language {
	color: #848687;
	font-size: 10px;
	background-color: #34393f;
	padding: 5px;
	border-radius: 2px;
}

.ActiveCommiterEntry {
	padding-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ActiveCommiterEntry h6 {
	font-size: 12px;
	font-weight: 300;
	color: rgba(255,255,255,0.75);
}

.flex-holder {
	display: flex;
}

.InitialsCircle {
	width: auto;
	border-radius: 50%;
	padding: 5px;
	width: 10px;
	height: 10px;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}	


.HorizontalBarBase {
	background-color: #3F4246;
	width: 200px;
	border-radius: 1px;
}

.HorizontalBarActive {
	background-color: #febb2a;
}


.RecentProjectEntry {
	margin-top: 15px;
	display: flex;
	align-items: center;
	/*justify-content: space-between;*/
	justify-content: flex-start;
	text-decoration: none;
	cursor: pointer;
}

.RecentProjectEntry .info {
	width: 250px;
}

.RecentProjectEntry .metadata {
	width: 100px;
}


.RecentProjectEntry:first-of-type {
	margin-top: 30px;
}

.RecentProjectEntry p {
	font-size: 9px;
	font-weight: 300;
	margin-top: 5px;
	font-family: "Roboto", sans-serif;
	font-style: italic;
	width: 90%;
	color: #87898a;
}

.RecentProjectEntry h3 {
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	font-size: 14px;
}

.RecentProjectEntry .LanguageTag {
	padding: 5px;
	background-color: #34393f;
	color: #848687;
	display: inline-block;
	border-radius: 5px;
	font-size: 10px;
}

.RecentProjectEntry .LastUpdated {
	display: inline-block;
	font-size: 10px;
	color: #8f9091;
	margin-bottom: 5px;
}

@media only screen and (max-width: 960px) {

	.Dashboard {
	  grid-template-columns: repeat(8, 50px [col-start]);
	}

	.ActivityGraph {
		grid-area: span 6 / span 8;
	}


	.ProjectBreakdown {
		grid-area: span 4 / span 4;
	}

	.DailyCommits {
		grid-area: span 2 / span 4;
		padding: 15px 10px 10px 15px;
	}

	.TestCoverage {
		grid-area: span 2 / span 4;
		padding: 15px 10px 10px 15px;
	}

	.TestCoverage > * {
		padding-bottom: 15px;
	}


	.NewReleases {
		grid-area: span 2 / span 4;
		padding: 15px 10px 10px 15px;
	}

	.NewReleases > * {
		padding-bottom: 15px;
	}


	.ActiveDevelopers {
		grid-area: span 2 / span 4;
		padding: 15px 10px 10px 15px;
	}

	.ActiveDevelopers > * {
		padding-bottom: 15px;
	}


	.RecentProjects {
		grid-area: span 7 / span 8;
		padding: 25px 10px 10px 20px;
	}

	.LatestCommits {
		grid-area: span 5 / span 8;
		padding: 25px 10px 10px 20px;
		overflow: hidden;
	}

	.ReviewRequests {
		grid-area: span 5 / span 8;
		padding: 20px;
	}

	.TrendingGithub {
		grid-area: span 5 / span 8;
		padding: 20px;
	}


}