.SharingModal {
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.5);
	font-family: "Roboto",sans-serif;
	position: absolute;
}

.ShareScorecard {
	position: relative;
	background-color: #191c1f;
	width: 800px;
	height: 600px;
	margin: auto;
	margin-top: 20vh;
}

.ShareHeader {
	height: 75px;
	background-color: #272b2f;
	display: flex;
	align-items: center;
	padding: 0px 30px 0px 30px;
	justify-content: space-between;
}

.ShareHeaderTitle {
	display: flex;
	align-items: center;
}

.ShareHeaderTitle img {
	width: 30px;
}

.ShareHeaderTitle h3 {
	font-weight: 300;
	margin-left: 20px;
	color: #b0b1b0;
}

.close-icon {
	width: 15px;
	cursor: pointer;
}

.SharingOptions {
	height: 75px;
	display: flex;
}

.SharingOption {
	width: 33%;
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;	
	cursor: pointer;
	border-bottom: 2px solid #191C1F;
}

.SharingOptions .ViaEmail {
	background-color: #2c3034;
}

.SharingOptions .ViaPermalink {
	background-color: #303539;
}

.SharingOptions .OnSlack {
	background-color: #41464b;
}

.SharingModal form {
	display: block;
	margin: 50px;
}

.SharingModal h5 {
	margin-top: 30px;
}

.SharingModal form input {
	display: block;
	background-color: #181b1e;
	border: 0px;
	border-bottom: 1px solid rgba(255,255,255,0.2);
	height: 30px;
	width: 80%;
	margin-bottom: 20px;
	color: #fff;
	font-weight: 100;
}

.SharingModal form div {
	color: #e75b84;
	font-weight: 300;
	font-size: 14px;
}

.SharingModal form input:-webkit-autofill,
				   input:-webkit-autofill:hover, 
				   input:-webkit-autofill:focus, 
				   input:-webkit-autofill:active,
				   input:-internal-autofill-selected {
	background-color: #181b1e;
	color: red;
}

input:-internal-autofill-selected {
	background-color: red;
}


.SharingModal form input[type="textarea"] {
	height: 80px;
}

.SharingModal textarea {
	height: 80px;
	margin-bottom: 10px;
	display: block;
	background-color: #181b1e;
	border: 0px;
	border-bottom: 1px solid rgba(255,255,255,0.2);
	width: 80%;
	margin-bottom: 20px;
	color: #fff;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	font-size: 14px;
}

.SharingModal textarea:focus {
	border: 0px;
	box-shadow: 0px;
	outline: none;
	border-bottom: 1px solid #e75b85;
}

.SharingModal label {
	display: flex;
	align-items: center;
	color: rgba(255,255,255,0.5);
	font-weight: 300;
	font-size: 14px;
}

.SharingModal form input[type="checkbox"] {
	height: 20px;
	background-color: red;
	width: auto;
	height: auto;
	display: inline-block;
	margin: 0px 5px 0px 0px;
}

.SharingModal form input:focus {
	border: 0px;
	box-shadow: 0px;
	outline: none;
	border-bottom: 1px solid #e75b85;
}

.SharingModal .ShareButton {
	padding: 10px 25px 10px 25px;
	width: auto;
	min-width: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	font-size: 14px;
	border-radius: 2px;
	background-color: #e75b85;
	color: #fff;
	border: 0px;
	box-shadow: 0px;
	outline: none;
	margin-top: 50px;
	border: 1px solid #171a1d;
}

.SharingModal .ShareButton:hover {
	background-color: #d3547c;
}

.SharingModal .ShareButton:active {
	background-color: red;
	background-color: #181b1e;
	transition: background-color 0.05s linear;
	transition: background 0.5s linear;
	/*border: 1px solid rgba(255,255,255,0.2)*/
}

.SharingModal .PermalinkSharing {
	margin: 100px 0px 0px 30px;
}

.SharingModal .GeneratedPermalinkContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px;
	border: 1px solid rgba(255,255,255,0.25);
	width: 90%;
	font-weight: 300;
	border-radius: 2px;
	font-size: 14px;
}

.SharingModal .GeneratedPermalink {
	font-size: 12px;
	margin: 5px;
	color: rgba(255,255,255,0.75);
}

.SharingModal .PermalinkShareButton {
	padding: 10px;
	background-color: #e75b85;
	cursor: pointer;
	border-radius: 3px;
	border: 1px solid #171a1d;
}

.SharingModal .PermalinkShareButton:hover {
	background-color: #d3547c;
	/*background-color: rgba(154,72,101,0.1);*/
}

.SharingModal .PermalinkShareButton:active {
	/*background-color: #fff;*/
	/*background-color: rgba(154,72,101,0.1);*/
	background-color: #181b1e;
	/*border: 1px solid rgba(255,255,255,0.1);*/
	transition: background-color 0.1s linear;
	transition: background 0.1s linear;
}

.SharingModal .SharingStatus {
	color: #e75b85;
	font-size: 12px;
	font-weight: 300;
	margin-top: 10px;
	display: none;
}


@media only screen and (max-width: 960px) {
	.ShareScorecard {
		width: 80vw;
		height: 600px;
		margin: auto;
		margin-top: 20vh;
	}
}