.EmptyAccountContainer {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.EmptyAccount {
	display: block;
	border: 1px solid rgba(255,255,255,0.1);
	border-radius: 2px;
	padding: 100px;
	margin-top: -200px;
}

.EmptyAccount h5 {
	font-size: 14px;
	margin-bottom: 20px;
}

.EmptyAccount .progresslogo {
	margin-bottom: 20px;
 	filter: grayscale(100%);
 	max-height: 100px;
}

.EmptyAccountContainer .Loader {
	margin-top: -20vh;
	justify-content: center;
	text-align: center;
}

.ContentLoader {
	display: grid;
	grid-template-rows: 22px 22px 22px;
	grid-template-columns: 22px 22px 22px;
	margin-bottom: 10px;
}

.ContentLoader .dot {
	width: 15px;
	height: 15px;
	background-color: rgba(255,255,255,0.75);
	border-radius: 50%;	
}

@keyframes leaves {
    0% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    100% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

@media only screen and (max-width: 1060px) {
	.EmptyAccountContainer {
		min-height: 100vh;
		width: 90vw;
	}
}
