.CodeViewerContainer {
	width: 960px;
	background-color: #292D31;
	min-height: 100vh;
	/*margin: 50px;*/
	font-size: 12px;
	line-height: 16px;
	font-weight: 300;
}

.NoFileFound {
	margin-left: 20px;
}