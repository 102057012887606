.SearchResultsContainer {
  background-color: rgb(29,31,33);
  margin: 50px;
  display: flex;
}

.SearchResults {
  padding: 25px 40px 25px 40px;
  min-height: 100vh;
  /*width: 860px;*/
  width: 1068px;
}

.SearchResults h3 {
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	font-weight: 100;
	line-height: 28px;
}

.SearchResults .QuerySummary {
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(255,255,255,0.1);
}

.SearchResultEntry {
	margin-top: 20px;
	padding-bottom: 42px;
	max-height: 50vh;
	overflow: hidden;
	border-bottom: 1px solid rgba(255,255,255,0.2);
	position: relative;
}

.SearchResultEntry a {
	text-decoration: none;
}

.SearchResultEntry img {
	width: 20px;
}

.SearchResultEntry h3 {
	font-weight: 300;
	color: #fff;
	cursor: pointer;
}

.SearchResultEntry h3:hover {
	color: #f05c8a;
}

.SearchResultEntry h5 {
	cursor: pointer;
}

.SearchResultEntry h5:hover {
	color: #f05c8a;
}


.SearchResultEntry pre {
	font-size: 12px;
	font-weight: 100;
}

.SearchResultEntry code {
	font-size: 12px;	
}

.SearchResultEntry .AddSnippet {
	width: 15px;
	position: absolute;
	top: 10px;
	right: 50px;
	cursor: pointer;
	opacity: 0;
}

.SearchResultEntry:hover .AddSnippet {
	display: block;
	opacity: 0.5;
	transition: 0.1s;
}

.SearchNav {
	display: flex;
	margin-top: 20px;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
}

.SearchResultsContainer strong {
	color: #fff;
}

.SearchNavTab {
	margin-right: 40px;
	padding-bottom: 10px;
	border-bottom: 1px solid #1c1e20;
	margin-bottom: 15px;
	cursor: pointer;
}

.SearchNavTab h5 {
	margin-top: 5px;
}

.GithubSearchGrid {
	/*margin: 50px;*/
	display: grid;
	grid-gap: 20px;
    column-gap: 20px;
    grid-row-gap: 20px;
	grid-template-columns: repeat(4, 252px [col-start]);
	margin-top: 50px;

}

.GithubSearchResult {
	border: 1px solid #202528;
	cursor: pointer;
	width: 250px;
	height: 250px;
	background-color: #292d31;
	border-radius: 3px;
	font-size: 12px;
	overflow: hidden;	
}

.GithubSearchResult .Content {
	padding: 20px;
	position: relative;
	height: 160px;
	overflow: hidden;
}

.GithubSearchResult .Content h3 {
	font-weight: 300;
	line-height: 24px;
	margin-bottom: 5px;
}

.GithubSearchResult .Content p {
	font-weight: 300;
	height: 73px;
	width: 90%;
	overflow: hidden;
}


.GithubSearchResult .button {
	padding: 10px;
	border: 1px solid rgba(255,255,255,0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 300;
	color: #f05c8a;
	margin: -10px 20px 0px 20px;
}

.GithubSearchResult .button:hover {
	background-color: #f05c8a;
	border: 1px solid #f05c8a;
	color: #fff;
}

/*.NoResults {
	min-height: 60vh;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
*/

@media only screen and (max-width: 1060px) {
	.GithubSearchGrid {
		display: grid;
		grid-template-columns: repeat(3, 252px [col-start]);
	}
}

@media only screen and (max-width: 960px) {
	.GithubSearchGrid {
		display: grid;
		grid-template-columns: repeat(2, 252px [col-start]);
	}
}
