.ProjectScorecard {
  display: grid;
  grid-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(14, 50px [col-start]);
  grid-template-rows: repeat(12, 50px [col-start]);
  margin: 50px;
}

h5 {
  text-transform: uppercase;
  font-size: 10px;
  color: #6d6f72;
  margin-bottom: 10px;
  min-height: 10px;
}

@keyframes loading {
  0% {border-top: 1px solid #212629;}
  50% {border-top: 1px solid #fff;}
  100% {border-top: 1px solid #212629;}
}

.ProjectCard {
  background-color: #292d31;
  padding: 15px;
  position: relative;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  overflow: hidden;
}

.ProjectCard .heavy {
  font-weight: 700;
}

.ProjectCard a {
  text-decoration: none;
  color: #fff;
}

.ProjectCard h2 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 24px;
  margin-bottom: -10px;
  min-height: 24px;
}

.ProjectCard h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 21px;
  font-weight: 100;
  min-height: 21px;
}

.ProjectCard h4 {
  margin-top: 5px;
  font-size: 10px;
  font-weight: 100;  
  min-height: 10px;
}

.ProjectCard img {
  margin-right: 10px;
}

.ProjectCard .topProjects {
  display: flex;
  align-items: center;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  min-height: 12px;
  margin-top: 25px;
  color: #a6a8a7;
}

.ProjectCard .topProjects img {
  width: 20px;
  height: 20px;
}

.ProjectCard .mini-title {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
  min-height: 12px;
  letter-spacing: 1px;
  color: #858588;
}

.ProjectScorecard .counterLarge {
  color: #FDBC2D;
  font-size: 36px;
  min-height: 36px;
  letter-spacing: -2px;
  min-width: 5px;
  display: block;
}

.ProjectTitleCard {
  /*grid-area: 1 / 1 / span 2 / span 4;*/
  grid-area: span 2 / span 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProjectTitleCard .projectDescription {
  width: 65%;
}

.ProjectTitleCard a h4 {
  font-weight: 300;
}

.CommitActivityCard {
  /*grid-area: 1 / 5 / span 5 / span 10;*/
  grid-area: span 5 / span 10;
  padding-top: 25px;
  overflow: hidden;
}

.CommitActivityCard svg {
  margin-left: -10px;
}

.ActivityCardContext {
  border-top: 1px solid rgba(255,255,255,0.1);
  width: 80%;
  margin: 10px 0px 10px 5px;
}

.ActivityCardContext h5 {
  font-size: 9px;
  margin-top: 10px;
  font-weight: 300;
  min-height: 9px;
}

.ActivityCardContext h3 {
  font-size: 12px;
  min-height: 12px;
  line-height: 21px;
}

.ProjectCommitsCard {
  /*grid-area: 3 / 1 / span 3 / span 4;*/
  grid-area: span 3 / span 4;
  padding-top: 30px;
  display: flex;
  align-items: center;
}

.ProjectCommitsCard h3 {
  color: rgba(255,255,255,0.8);
}

.ProjectCommitsCard .counterLarge {
  font-size: 48px;
  letter-spacing: -5px;
  margin-bottom: 5px;
  min-height: 48px;
}

.LastActivityCard {
  /*grid-area: 6 / 1 / span 2 / span 5;*/
  grid-area: span 2 / span 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LastActivityCard div {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 10px;
  min-height: 10px;
  line-height: 14px;
  letter-spacing: 0.5px;
}

.LastActivityCard img {
  width: 50px;
}

.ActiveReleasesCard {
  /*grid-area: 6 / 6 / span 2 / span 9;*/
  grid-area: span 2 / span 9;
}

.ContributorsCard {
  /*grid-area: 8 / 1 / span 3 / span 5;*/
  grid-area: span 3 / span 5;
  display: flex;
  align-items: center;
}

.ReleasesCard {
  /*grid-area: 8 / 6 / span 3 / span 4;*/
  grid-area: span 3 / span 4;
  display: flex;
  align-items: center;
}

.MiscCard {
  /*grid-area: 8 / 10 / span 3 / span 5;*/
  grid-area: span 3 / span 5;
}

.BranchesCard {
  /*grid-area: 8 / 10 / span 3 / span 5;*/
  grid-area: span 3 / span 5;
  display: flex;
  align-items: center;
}

.CommitHistoryCard {
  /* grid-area: 11 / 1 / span 9 / span 14;*/
  grid-area: span 9 / span 14;
}

.ScorecardContainer {
  display: flex;
}

.ScorecardControl p {
  font-size: 8px;
  color: #858588;
  width: 70%;
  max-width: 300px;
  margin-top: 20px;
  min-height: 8px;
}

.ScorecardEdit {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.ScorecardEdit h4 {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  min-height: 12px;
}

.EditToggle {
  height: 20px;
  width: 40px;
  background-color: #8d9096;
  margin: 10px;
  display: flex;
  border-radius: 20px;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  justify-content: flex-start;
} 

.ToggleButton {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 1px;
}


.ActiveReleasesCard .ReleasesList {
  display: flex;
 /* margin-left: 10px;*/
  overflow: hidden;  
}

.ActiveReleasesCard .ReleasesList h3 {
  font-size: 14px;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.5px;
  margin-top: 20px;
  min-height: 14px;
}

.ActiveReleasesCard .ReleasesList h4 {
  /*margin-left: -10px;*/
  margin-top: 20px;
  color: rgba(255,255,255,0.8);
}

.ActiveReleasesCard .ReleasesList > * {
  margin-right: 30px;  
}

.ProjectCard .EditModeControl {
  position: absolute;
  top: 20px;
  right: 10px;
}

.ProjectCard .EditModeControl > * {
  padding-bottom: 5px;
}

.ProjectCard .ControlButton {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 10px;
  color: #ef5b89;
  cursor: pointer;
}

.ProjectCard .ControlButton img {
  width: 15px;
}


.projectDescription h2 {
  text-transform: capitalize;
  line-height: 21px;
  height: 100%;
  margin-bottom: 5px;
}

.projectDescription h4 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  /*line-height: 14px;*/
  color: rgba(255,255,255,0.6);
  max-height: 60px;
  overflow: hidden;
}

.github-link {
  vertical-align: top;
  display: inline-block;
  text-align: right;  
}

.github-link h4 {
  color: rgba(255,255,255,0.8);
  font-size: 8px;
  min-height: 8px;
  display: block;
  text-align: right;
  /*word-break: break-all;*/
}

.github-link img {
  width: 30px;
  margin: 0px;
}

.CommitHistoryContainer {
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
}

.CommitHistoryEntry {
  width: 32%;
  margin-top: 25px;
}

.CommitHistoryEntry h4 {
  max-width: 80%;
  font-weight: 300;
  color: rgba(255,255,255,0.8);
  margin-bottom: 10px;
  max-height: 100px;
  overflow: hidden;
}

.CommitActivityCard .CommitHistoryEntry {
  width: 100%;
  margin-top: 10px;
}

.ActivityTimelineHolder {
  display: flex;
  align-items: center;  
}

.ActivityTimeline {
  min-height: 170px;
}

.ActivityStatistics {
  margin-left: 20px;
}

.ActivityStatistics h5 {
  font-size: 8px;
  min-height: 8px;
}

.ActivityStatistics h3 {
  font-size: 12px;
  min-height: 12px;
}

@media only screen and (max-width: 1120px) {

  .ProjectScorecard {
    grid-template-columns: repeat(12, 50px [col-start]);
    grid-template-rows: repeat(12, 50px [col-start]);
    max-width: 100%;
  }
  .ScorecardContainer {
    max-width: 960px;
  }

  .ScorecardEdit {
    display: none;
  }

}

@media only screen and (max-width: 960px) {
  
  .ProjectScorecard {
    grid-template-columns: repeat(12, 50px [col-start]);
    grid-template-rows: repeat(12, 50px [col-start]);
  }

  .ProjectTitleCard {
    /*grid-area: 1 / 1 / span 2 / span 4;*/
    grid-area: span 2 / span 3;
  }

  .CommitActivityCard {
    /*grid-area: 3 / 1 / span 5 / span 11;*/
    grid-area: span 5 / span 10;
  }

  .ProjectCommitsCard {
    /*grid-area: 1 / 5 / span 2 / span 3;*/
    grid-area: span 3 / span 0;
    /*padding: 10px;*/
    /*display: none;*/
  }

  .ProjectCommitsCard .counterLarge {
    font-size: 32px;
  }

  .LastActivityCard {
    /*grid-area: 1 / 8 / span 2 / span 4;*/
    grid-area: span 2 / span 0;
  }

  .ActiveReleasesCard {
    /*grid-area: 8 / 1 / span 2 / span 7;*/
    grid-area: span 2 / span 0;
  }

  .ContributorsCard {
    /*grid-area: 10 / 1 / span 3 / span 5;*/
    grid-area: span 3 / span 0;
  }

  .ReleasesCard {
    /*grid-area: 10 / 6 / span 3 / span 6;*/
    grid-area: span 3 / span 0;
  }

  .BranchesCard {
    /*grid-area: 8 / 8 / span 2 / span 4;*/
    grid-area: span 3 / span 0;
  }

  .CommitHistoryCard {
    /*grid-area: 13 / 1 / span 9 / span 14;*/
    grid-area: span 9 / span 10;
  }
}

