/*.ContributorGraph {
	background-color: #292d31;
	min-height: 70vh;
	margin: 50px;
	font-size: 10px;
}
*/

.ContributorGraphMeta {
  display: flex;
}

.ContributorGraphContainer {
  background-color: #292d31;
  margin: 50px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ContributorGraph {
  min-height: 100vh;
  display: grid;
  grid-gap: 20px;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  grid-template-columns: 200px repeat(25, 25px [col-start]);
  /*grid-template-rows: auto auto 1fr 1fr 1fr auto auto;*/
  /*grid-template-rows: repeat(12, 15px [col-start]);*/
  /*grid-template-rows: repeat(12, 20px [col-start]);*/
  /*height: calc(100vh - 10px);*/
  grid-auto-rows: 15px;
  padding: 25px;
  overflow: hidden;
  font-size: 10px;
}

.ContributorName {
  display: flex;
  align-items: center;
}

.ContributorGraphLabel {
  font-weight: 300;
  color: rgba(255,255,255,0.3);
  font-size: 10px;
}

.ContributorActivityEntry {
  border-radius: 1px;
  border: 1px solid rgba(255,255,255,0);
  cursor: pointer;
}

.ContributorActivityEntry:hover {
  border: 1px solid rgba(255,255,255,0.1);
}

.ContributorGraphMeta .FileList {
  font-size: 10px;
  line-height: 18px;
  font-weight: 300;
  margin-top: 50px;
  background-color: #212629;
}

.ContributorGraphMeta .FileList > div:hover {
  background-color: #FDBB2D;
  cursor: pointer;
  color: #000;
}

@media only screen and (max-width: 1280px) {

  .ContributorGraph {
    grid-template-columns: 100px repeat(25, 20px [col-start]);
  }

  .ContributorGraphLabel {
    font-size: 8px;
    /*color: #202528;*/
  }

}


@media only screen and (max-width: 1060px) {
  .ContributorGraph {
    min-height: 100vh;
    display: grid;
    grid-gap: 10px;
    grid-column-gap: 3px;
    grid-row-gap: 5px;
    grid-template-columns: 80px repeat(25, 15px [col-start]);
    grid-auto-rows: 10px;
    padding: 15px;
    overflow: hidden;
    font-size: 8px;
  }

  .ContributorGraphLabel {
    font-size: 6px;
    /*color: #202528;*/
  }

}