.CodeGraphContainer {
	display: flex;
}

.CodeGraph {
  background-color: #292d31;
  display: grid;
  grid-gap: 60px;
  column-gap: 20px;
  grid-row-gap: 40px;
  grid-template-columns: repeat(4, 210px [col-start]);
  grid-template-rows: repeat(6, 210px [col-start]);
  padding: 50px 0px 0px 25px;
  /*margin: 0 25px 50px 50px;*/
  /*width: 100%;*/
  /*padding: 25px;*/
  /*overflow: hidden;*/
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
}

.GraphNode {
	cursor: pointer;
}

.FileList {
	font-size: 10px;
	line-height: 18px;
	font-weight: 300;
	margin-top: 50px;
	width: 300px;
}

.FileListHeader {
	display: flex;
	justify-content: space-between;
	padding-left: 25px;
/*	background-color: green;*/
/*	border-bottom: 1px solid rgba(255,255,255,0.1);*/
/*	margin-bottom: 10px;*/
}

.FileListHeader h5 {
	cursor: pointer;
}

.FileListHeader h5:hover {
	color: #fff;
}


.FileList .dot {
	display: inline-block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	margin: 0px 10px 0px 10px;
	background-color: rgba(255,255,255,0.3);
}

.FileList .dot:hover {
	background-color: #000;
}

.FileListEntry {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.FileListEntry a {
	text-decoration: none;
	color: #fff;
}


.FileNameContainer {
	max-width: 250px;
}

.FileName {
	display: inline-block;
	margin-right: 20px;
	max-width: 250px;
	/*overflow: hidden;*/
/*	overflow-wrap: anywhere;
	word-break: break-all;*/
}

.file-size {
	font-weight: 300;
	font-size: 10px;
}


/*.FileList > div:hover {*/
.FileListEntry:hover {
	background-color: #FDBB2D;
	cursor: pointer;
	color: #000;
}

.GraphVisualizationWrapper {
	width: 960px;
	margin: 50px 25px 50px 50px;
}

.ActivePath {
	padding: 20px;
	cursor: pointer;
	position: relative;
	font-weight: 300;
	width: 100%;
	background-color: #292D31;
	/*height: 10px;*/
}

.BackNav {
	background-color: #292D31;
	padding: 20px;
	cursor: pointer;
	display: none;
}

.BackNav img {
	height: 20px;
	margin-right: 5px;
}

.CodeGraph svg {
	border: 1px solid #282c30;
}

.CodeGraph svg:hover {
	border: 1px solid rgba(254,255,254,0.1);
	cursor: pointer;
	border-radius: 5px;
	background-color: rgba(0,0,0,0.0);
}

.ActivePath .RepoName {
	font-weight: 500;
}

.ActivePath .LastCommitMessage {
	font-size: 10px;
	color: rgba(255,255,255,0.5);
	line-height: 14px;
	margin-top: 5px;
}

.GraphHeader {
	display: flex;
	justify-content: space-between;
}

@media only screen and (max-width: 1400px) {
	.GraphVisualizationWrapper {
		width: 800px;
		max-width: 60vw;
	}

	.CodeGraph {
	  display: grid;
	  grid-template-columns: repeat(3, 210px [col-start]);
	}

}

@media only screen and (max-width: 1280px) {

	.GraphVisualizationWrapper {
		width: 700px;
		max-width: 50vw;
	}

	.CodeGraph {
	  display: grid;
	  grid-template-columns: repeat(2, 210px [col-start]);
	}

}

@media only screen and (max-width: 960px) {

	.CodeGraph {
	  display: grid;
	  grid-template-columns: repeat(1, 210px [col-start]);
	}

	.GraphVisualizationWrapper {
		width: 600px;
		max-width: 35vw;
	}

	.GraphVisualizationWrapper {
		width: 540px;
		margin: 50px 25px 50px 50px;
	}

}