.SnippetsWrapper {
    margin: 50px;
}

.SnippetsWrapper h3 {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 18px;
}

.SnippetsContainer {
  display: flex;
}

.Snippets {
  display: grid;
  grid-gap: 10px;
  grid-row-gap: 10px;
  column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(3, 350px [col-start]);
  overflow: hidden;
  min-height: 100vh;
  font-size: 10px;
  font-weight: 300;
}

.SnippetPreview {
  width: 100%;
  word-wrap: break-word;
}

.Snippets h4 {
  font-size: 14px;
  margin-bottom: 5px;
}

.Snippets .SnippetHeader {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255,255,255,0.25);
}

.Snippets pre {
  height: 420px;
  overflow: hidden;
  border-radius: 3px;
}

@media only screen and (max-width: 1280px) {
  .Snippets {
    grid-template-columns: repeat(2, 350px [col-start]);
  }
}

@media only screen and (max-width: 1060px) {
  .Snippets {
    grid-template-columns: repeat(1, 350px [col-start]);
  }
}