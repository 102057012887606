.CodeBranches {
	margin: 50px;
	display: grid;
	grid-gap: 20px;
    column-gap: 20px;
    grid-row-gap: 20px;
	grid-template-columns: repeat(4, 252px [col-start]);
}

.BranchCard {
	border: 1px solid #202528;
	cursor: pointer;
	width: 250px;
	height: 250px;
	background-color: #292d31;
	border-radius: 3px;
	font-size: 12px;
	overflow: hidden;
}

.Selected {
	background-color: #FEBC2E;
	color: #FEBC2E;
}

.Selected div {
	color: #212629;
}

.BranchCard:hover {
	border: 1px solid #fff;
}

.Selected:hover {
	border: 1px solid #202528;
}

.CardWrapper {
	margin: 20px;
}

.BranchTitleWrapper {
	display: flex;
	justify-content: space-between;
}

.BranchCard img {
	width: 20px;
}

.BranchCard .highlight {
	color: #fff;
}

.Selected .highlight {
	color: #000;
}

.BranchTitle {
	display: flex;
	align-items: center;
}

.BranchTitle h4 {
	font-size: 12px;
}

.circle {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #febc2d;
	margin-right: 5px;
}

.Selected .circle {
	/*background-color: #fff;*/
	border-radius: 0px;
	border-bottom: 1px solid #000;
}

.LastCommit {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	color: #c7c9c9;
}

.CommitTimestamp {
	min-width: 100px;
}

.CommitTimestamp > div:first-child {
	font-size: 10px;
	line-height: 18px;
}


.CommitAuthor {
	font-size: 10px;
	max-width: 150px;
}


.CommitAuthor > div:first-child {
	font-size: 10px;
	line-height: 18px;
}

.CommitAuthor > div:last-child {
	font-size: 6px;
	line-height: 6px;
	margin-top: 5px;
}


.CardWrapper .CommitMessage {
	font-family: "Roboto Mono", monospace;
	margin-top: 20px;
	font-weight: 300;
	margin-left: 0px;
}

@media only screen and (max-width: 1260px) {

	.CodeBranches {
		grid-template-columns: repeat(3, 252px [col-start]);
	}

}

@media only screen and (max-width: 960px) {

	.CodeBranches {
		grid-template-columns: repeat(2, 252px [col-start]);
	}

}