body {
  width: 100%;
}

.MasterFrame {
  background-color: #212629;
  font-family: "Roboto Mono", monospace;
}

.CodeWebHeader {
  height: 80px;
  background-color: #2A2E32;
  display: flex;
}

.CodeWebLogo {
  min-width: 100px;
  height: 80px;
  background-color: #202428;
  background-image: url("img/codeweb-logo.svg");
  background-repeat: no-repeat;
  background-size: 40% auto;
  background-position: center;
}

.HeaderControl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ImportProjectButton {
  padding: 12px 20px 12px 25px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: #9A4865;
  border: 1px solid #9A4865;
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImportProjectButton:hover {
  background-color: rgba(154,72,101,0.1);
  color: #cfd4dd;
}

.ImportProjectButton .dash {
  height: 18px;
  margin-left: 10px;
  margin-right: 10px;
  border-right: 1px solid #9A4865;
}

.ImportProjectButton img {
  height: 10px;
}

.WebsiteSearch {
  display: flex;
  align-items: center;
  height: 80px;
  padding-left: 50px;
  line-height: 80px;
  width: 80%;
  max-width: 960px;
}

.WebsiteSearch img {
  height: 20px;
  width: 20px;
  display: block;
  margin-right: 10px;
}

.WebsiteSearch .search-label {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  color: #79797A;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.UserHeader {
  display: flex;
  align-items: center;
  min-width: 300px;
}

.HeaderNotifications {
  width: 50px;
  cursor: pointer;
}

.HeaderNotifications img {
  width: 25px;
}

.UserPreferences {
  width: 30px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  float: right;
}

.UserPreferences img {
  width: 30px;
}

.UserPreferenceMenu {
    display: none;
    position: absolute;
    right: 0;
    z-index: 1;
    background-color: #282c30;
    border: 1px solid rgba(255,255,255,0.1);
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    border-radius: 2px;
    border-top: 1px solid #ef5b89;
    min-width: 160px;
    padding: 0px 0px 10px 0px;
}

.UserPreferences:hover >  .UserPreferenceMenu {
  display: block;
}

.UserPreferenceMenu h5 {
  color: rgba(255,255,255,0.5);
  font-size: 10px;
  line-height: 18px;
}

.UserPreferenceMenu .UserDetails {
    background-color: rgba(255,255,255,0.05);
    padding-top: 20px;
    margin-bottom: 10px;
}

.UserPreferenceMenu li {
  font-size: 14px;
  min-height: 21px;
  padding: 10px 20px 10px 20px;
}

.UserPreferenceMenu .MenuOption:hover {
  background-color: #ef5b89;
}


.MenuBar {
  min-width: 100px;
  background-color: #292d31;
}

.MenuBar ul {
  width: 40px;
  margin: auto;
  padding-top: 40px;
}

.MenuBar li {
  margin-bottom: 10px;
  height: 40px;
  border-radius: 100%;
  display: flex;  
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

.MenuBar .selected {
  background-color: #EF5B8A;
}

.MenuBar img {
  width: 25px;
  height: 25px;
  margin-top: 7px;
}


.ContentContainer {
  display: flex;  
}

.ContentNavigator {
  min-height: 100vh;

}

.NavigatorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 50px;
  margin-top: 40px;
  width: 1150px;
}

.BranchSelector {
  width: 200px;
}

.BranchSelector .Container {
  display: flex;
  font-weight: 100;
  cursor: pointer;
}

.BranchSelector .dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #FEBC2D;
}

.BranchSelector h4 {
  color: rgba(255,255,255,0.8);
  font-weight: 300;
  font-size: 12px;
}

.BranchSelector h5 {
  font-size: 12px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  text-transform: none;
  color: rgba(255,255,255,0.8);
}

.BranchSelector .strong {
  font-weight: 500;
}

.BranchSelector .BranchLabel {
  display: flex;
  align-items: center;
  margin-left: 0px;
}

.BranchSelector .BranchLabel img {
  margin-right: 5px;
  margin-left: 2px;
} 

.BranchSelector img {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  padding-top: 5px;
}

.WorkflowSelector {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-right: 100px;
}

.WorkflowSelector ul {
  display: flex;
}

.WorkflowSelector li {
  padding: 20px;  
  color: #8d9096;
  cursor: pointer;
  border-bottom: 1px solid rgba(255,255,255,0.2);
}

.WorkflowSelector .selected {
  color: #fff;
  border-bottom: 4px solid #EF5B8A;
}

.BranchSelectDropdown {
  position: relative;
  display: block;
  /*width: 100%;*/
}

.BranchSelectContent {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #202428;
  padding: 10px 0px 10px 0px;
  font-size: 12px;
  font-weight: 14px;
  font-family: "Roboto", san-serif;
  font-weight: 300;
  border-bottom: 1px solid rgba(255,255,255,0.2);
  cursor: pointer;
  /*width: 100%;*/
  min-width: 250px;
  font-size: 14px;
}

.BranchSelectContent li {
  /*padding: 10px;*/
  padding: 10px 20px 5px 10px;
  /*width: 100%;*/
}

.BranchSelectContent h5 {
  font-size: 10px;
  font-weight: 300;
  margin-top: 5px;
}

.BranchSelectContent li:hover {
  background-color: #fdbc2d;
  color: #000;
}

.BranchSelectContent li:hover h5 {
  color: rgba(0,0,0,0.8);
}

.WebsiteSearch input[type=text] {   
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #292d31;
    /*width: 500px;*/
    width: 80%;
    margin-left: 5px;
    color: #fff;
    border-radius: 2px;
    outline: none;
    border: 0px;
    font-size: 12px;
    font-weight: 300;
}

input[type=text]:focus {
  outline: none;
}

.SharingLink {
  margin-left: -50px;
  width: 180px;
}

.SharingButton {
  color: #dbdcdb;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;  
  cursor: pointer;
}

.SharingLink .ShareText {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 300;
}
.SharingLink img {
  width: 25px;
}


/* general */

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.ErrorMessage {
  color: #f05c8a;
  font-size: 14px;
  font-weight: 300;
}

@media only screen and (max-width: 1360px) {

  .NavigatorHeader {
    justify-content: flex-start;
  }

  .SharingLink {
    margin-left: 0;
    margin-top: 25px;
    width: 180px;
  }

}

@media only screen and (max-width: 1060px) {

  .NavigatorHeader {
    display: block;
  }
}


@media only screen and (max-width: 960px) {

  .NavigatorHeader {
    display: block;
  }

  .BranchSelector {
    margin-bottom: 25px;
  }

}

