.ImportModal {
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.15);
	padding: 100px;
	font-family: "Roboto",sans-serif;
}

.ImportNewProject {
	position: relative;
	background-color: #191c1f;
	width: 600px;
	height: 400px;
	margin: auto;
	margin-top: 5vh;
	padding: 100px;
}

.ImportNewProject h1 {
	font-size: 24px;
	font-weight: 500;
}

.ImportNewProject h3 {
	font-size: 14px;
	font-weight: 300;
	color: rgba(255,255,255,0.3);
	margin-bottom: 30px;
}

.ImportNewProject h5 {
	color: rgba(255,255,255,0.5);
}

.ImportNewProject p {
	font-size: 14px;
	color: #5f6164;
	font-weight: 300;
	margin-top: 5px;
}

.ImportNewProject .close-icon {
	width: 15px;
	position: absolute;
	top: 50px;
	right: 50px;
	cursor: pointer;
}

.ImportNewProject a {
	text-decoration: none;
	color: #f05c8a;
}

.StepController {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background-color: #262a2e;
}

.ImportModal .TitleContainer {
	padding-bottom: 25px;
	border-bottom: 1px solid rgba(255,255,255,0.3);
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.TitleContainer img {
	display: inline-block;
	position: static;
	width: 40px;
	margin-right: 20px;
}


.ImportModal input[type=text] {   
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #262a2e;
    padding: 10px;
    height: 25px;
    width: 95%;
    margin-bottom: 25px;
    color: #fff;
    border-radius: 2px;
    outline: none;
    border: 0px;
    font-size: 12px;
}

input[type=text]:focus {
	outline: none;
}

.ImportModal li {
	display: inline-block;
	margin-right: 20px;
	padding-bottom: 10px;
	margin-bottom: 30px;
	color: #7a7d7e;
	font-size: 12px;
	font-weight: 300;
}

.ImportModal li:first-of-type {
	border-bottom: 1px solid #ef5d88;
}

.ImportModal .TOC {
	color: #6b6d6d;
	display: flex;
	align-items: center;
	font-size: 12px;
	margin-top: 20px;
	font-weight: 300;
}

.TOC input {
	margin-right: 10px;
	padding: 5px;
}

.ImportModal .ControlContainer {
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: space-between;
	width: 75%;
	margin: auto;
}

.ImportModal .Button {
	padding: 10px 25px 10px 25px;
	width: auto;
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	font-size: 14px;
	border-radius: 2px;
}

.back-button {
	background-color: transparent;
	color: #ef5c89;
	border: 1px solid #ef5c89;
}

.forward-button {
	background-color: #ef5c89;
}

.ImportNewProject h4 {
	font-size: 12px;
	color: #fff;
}

.import-progress-icon {
	height: 75px;
}

.progress-bar-container {
	height: 15px;
	width: 100%;
	background-color: #313843;
	border-radius: 5px;
}

.progress-bar-container h5 {
	margin-top: 10px;
}

@keyframes importing {
  0% {width: 0%}
  100% {width: 100%}
}

.progress-bar {
	background-color: #ef5c89;
	width: 50%;
	height: 100%;
	border-radius: 5px;
	animation-iteration-count: infinite;
  	animation-timing-function: linear;
	animation-duration: 10s;
}
