.ContentLoading {
}

.ContentLoading .dot-1 {
	animation: pulse 1s infinite;
}

.ContentLoading .dot-2 {
	animation: pulse 2s infinite;
}

.ContentLoading .dot-3 {
	animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}